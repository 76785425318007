import { supportPageLinks } from '@chegg-wt/core/constants/supportPageLinks'
import { Config } from '@chegg-wt/core/config'
import { FooterColumn } from '@chegg-wt/core/types/core'

export const footerLinkColumns: FooterColumn[] = [
  {
    title: 'Company',
    links: [
      { name: 'Chegg Inc.', url: Config.cheggEnvUrl },
      { name: 'Contact Us', url: supportPageLinks.citethisforme },
      { name: 'Support', url: supportPageLinks.citethisforme },
    ],
  },
  {
    title: 'Resources',
    links: [
      {
        name: 'Cite This For Me™ Premium',
        url: `${Config.baseUrl}/upgrade`,
      },
      {
        name: 'Style Guides',
        url: `${Config.baseUrl}/guides`,
      },
      { name: 'Blog', url: `${Config.baseUrl}/blog` },
      {
        name: 'Chrome Extension',
        url:
          'https://chrome.google.com/webstore/detail/cite-this-for-me-web-cite/nnnmhgkokpalnmbeighfomegjfkklkle',
      },
    ],
  },
  {
    title: 'Follow Us',
    links: [
      {
        name: 'Facebook',
        url: 'https://www.facebook.com/CiteThisForMe/',
        rel: 'nofollow',
      },
      { name: 'Twitter', url: 'https://twitter.com/citethisforme', rel: 'nofollow' },
    ],
  },
  {
    title: 'Business',
    links: [
      {
        name: 'Terms of Use',
        url: `${Config.cheggEnvUrl}/termsofuse`,
      },
      {
        name: 'Global Privacy Policy',
        url: `${Config.cheggEnvUrl}/privacypolicy`,
      },
      {
        name: 'Cookie Notice',
        url: `${Config.cheggEnvUrl}/cookienotice/`,
      },
    ],
  },
]
