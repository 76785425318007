import { getUrlStyle } from '@chegg-wt/core/server/utils/redirect'
import { Config } from '@chegg-wt/core/config'
import { AppRouteCreators } from '@chegg-wt/core/types/core'

const writingDashboard = `${Config.cheggEnvUrl}/writing/dashboard`

/**
 * These represent the major points in our application
 *
 * { href } -> indicates a link that will be handled by the browser, whether external or not
 * { href, as } -> indicates a link that will be handled by NextJS via it's SPA routing mechanisms
 */
export const routes: AppRouteCreators = {
  toCitationsHome: () => ({ href: '/' }),
  toProject: () => ({ href: '/project', as: '/project' }),
  toProfile: () => ({ href: '/myaccount', as: '/myaccount' }),
  toLogout: () => ({ href: '/logout', as: '/logout' }),
  /**
   * Returns WebRoute because there are issues with chegg auth
   */
  toUpgrade: () => ({ href: '/upgrade' }),
  toUpgradeCTA: () => ({ href: '/upgrade' }),
  toSignup: () => ({ href: '/register' }),
  toLogin: () => ({ href: '/login' }),
  toPlagiarism: () => ({ href: `${Config.cheggEnvUrl}/tools/writing-center` }),
  toGateway: () => ({ href: '/gateway', as: '/gateway' }),
  toSourceTypeSelection: ({ style }) => ({
    href: '/[style]/source-type',
    as: `/${getUrlStyle(style)}/source-type`,
  }),
  toSourceSearch: ({ sourceType }) => ({
    href: '/cite/[source]',
    as: `/cite/${sourceType}`,
  }),
  toSourceSelection: ({ sourceType, query }) => ({
    href: `/cite/[source]/autocite?q=${query}`,
    as: `/cite/${sourceType}/autocite?q=${query}`,
  }),
  toSourceConfirmation: ({ sourceType }) => ({
    href: '/cite/sources/[source]',
    as: `/cite/sources/${sourceType}autociteeval`,
  }),
  toNewCitation: ({ sourceType }) => ({
    href: '/cite/sources/[source]',
    as: `/cite/sources/${sourceType}autociteconfirm`,
  }),
  toManualCitation: ({ sourceType }) => ({
    href: '/cite/sources/[source]',
    as: `/cite/sources/${sourceType}manualcite`,
  }),
  toEditCitation: ({ citationId }) => ({
    href: '/cite/edit/[id]',
    as: `/cite/edit/${citationId}`,
  }),
  toBibliography: ({ projectId }) => ({
    href: '/bibliographies/[id]',
    as: `/bibliographies/${projectId}`,
  }),
  toFolders: ({ create } = {}) => {
    if (create)
      return { href: `/folders?create=${create}`, as: `/folders?create=${create}` }
    return { href: '/folders', as: '/folders' }
  },
  toDashboard: () => ({ href: writingDashboard }),
  toCitationsDashboard: () => ({
    href: '/does/not/exist',
  }),
  toCitationList: () => ({
    href: '/does/not/exist',
  }),
  toCitationListSource: () => ({
    href: '/does/not/exist',
  }),
  toCitationListForm: () => ({
    href: '/does/not/exist',
  }),
  toUploadLp: () => ({ href: '/does/not/exist' }),
  toPapersDashboard: () => ({
    href: '/does/not/exist',
  }),
  toError: () => ({
    href: '/does/not/exist',
  }),
}
