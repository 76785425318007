/* eslint-disable import/prefer-default-export */
import { ParsedUrlQuery } from 'querystring'
import { matchSourceSegmentCTFM } from '@chegg-wt/core/utils/routes'
import { getSegmentFn, getIsExactFn } from '@chegg-wt/core/utils/url'
import { GetFromQuery, IsExact } from '@chegg-wt/core/types/core'
import { Config } from '@chegg-wt/core/config'

export const sourceSegmentRoutes = ['/cite/sources/[source]']

export const getSourceFromQuery: GetFromQuery = getSegmentFn({
  segmentRoutes: sourceSegmentRoutes,
  matchSegment: matchSourceSegmentCTFM,
  paramName: 'source',
})

export const isSourceExact: IsExact = getIsExactFn({
  segmentRoutes: sourceSegmentRoutes,
  matchSegment: matchSourceSegmentCTFM,
})

export const getCanonicalUrl = (route: string, query: ParsedUrlQuery): string | null => {
  if (route === '/') return Config.baseUrl
  // source selection page
  if (route === '/[style]/source-type' && query.style)
    return `${Config.baseUrl}/${query.style}/source-type`
  // create citation for selected source page
  if (route === '/cite/[source]' && query.source)
    return `${Config.baseUrl}/cite/${query.source}`
  return null
}

export const isNonCanonicalRoute = (route: string) => {
  switch (route) {
    case '/cite/sources/[source]': // manual, eval and confirm urls
    case '/cite/[source]/autocite': // search urls
    case '/cite/edit/[id]': // edit url
      return true
    default:
      return false
  }
}
