import { HeadTags } from '@chegg-wt/core/types/core'

export const head: HeadTags = {
  // title should eventually be changed and made page specific instead of app specific
  title:
    'Cite This For Me™: Free Bibliography Generator - MLA, APA, Chicago citation styles',
  metaTags: [
    {
      name: 'description',
      content:
        'Automatic works cited and bibliography formatting for MLA, APA and Chicago/Turabian citation styles. Now supports 7th edition of MLA.',
    },
  ],
}
