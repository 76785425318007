import { createSelector } from 'reselect'
import { AppState } from '@chegg-wt/core/state'
import { Config } from '@chegg-wt/core/config'
import {
  MY_CITATION_PROJECT_LIST_TITLE,
  NEW_CITATION_LIST_BUTTON,
  CITATION_LIST_NAME_PLURAL,
  CITATION_LIST_NAME_LOWER_SINGLE,
} from '@chegg-wt/core/constants/citations'
import { NavItem, NavItems } from '@chegg-wt/core/types/core'
import { currentProjectIdSelector } from '@chegg-wt/core/state/currentProject/selectors'
import { userEmailSelector, isProSelector } from '@chegg-wt/core/state/user/selectors'
import { supportPageLinks } from '@chegg-wt/core/constants/supportPageLinks'

export const navItemsSelector: (state: AppState) => NavItems = createSelector(
  [isProSelector, userEmailSelector, currentProjectIdSelector],
  (paidUser: boolean, email: string, currentProjectId: string) => {
    const main: NavItem[] = [
      {
        name: 'Plagiarism and grammar',
        icon: 'citation-guides',
        route: 'toPlagiarism',
        target: '_blank',
      },
      {
        name: 'Citation guides',
        icon: 'citation-guides',
        href: `${Config.baseUrl}/guides`,
      },
      {
        name: 'Support',
        icon: 'support',
        href: `${supportPageLinks.citethisforme}/?Website=External`,
      },
    ]

    let right: NavItem[] = []

    const account: NavItem[] = [
      {
        name: MY_CITATION_PROJECT_LIST_TITLE,
        icon: 'projects',
        route: 'toFolders',
      },
      {
        name: NEW_CITATION_LIST_BUTTON,
        icon: 'add',
        route: 'toFolders',
        query: {
          create: 'project',
        },
      },
      {
        name: 'My account',
        icon: 'settings',
        route: 'toProfile',
      },
      {
        name: 'Logout',
        icon: 'logout',
        route: 'toLogout',
      },
    ]

    const header: NavItem[] = []

    if (currentProjectId) {
      right = [
        ...right,
        {
          name: `My ${CITATION_LIST_NAME_LOWER_SINGLE}`,
          route: 'toProject',
          hideOnMobile: true,
          hideOnSmall: true,
        },
      ]
    }

    if (!paidUser) {
      main.splice(3, 0, {
        name: 'Cite This For Me™ Premium',
        icon: 'plus',
        route: 'toUpgrade',
      })

      right = [
        ...right,
        {
          name: 'Upgrade',
          route: 'toUpgrade',
          hideOnMobile: true,
        },
      ]

      account.splice(3, 0, {
        name: 'Upgrade',
        icon: 'plus',
        route: 'toUpgrade',
      })
    }

    if (!email) {
      right = [
        ...right,
        {
          name: 'Sign up',
          route: 'toSignup',
        },
        {
          name: 'Sign in',
          route: 'toLogin',
        },
      ]
    }

    if (email) {
      header.splice(1, 0, {
        name: CITATION_LIST_NAME_PLURAL,
        route: 'toFolders',
        hideOnSmall: true,
      })
      header.splice(2, 0, {
        name: 'Papers',
        href: Config.writingUrl,
        hideOnSmall: true,
      })
    }

    return { main, right, account, header }
  }
)
