/* eslint-disable react/jsx-filename-extension */
import '@chegg-wt/core/theme/form.scss'
import '@chegg-wt/core/theme/_index.scss'

import cookies from 'next-cookies'
import NextApp from 'next/app'
import { withRouter } from 'next/router'
import React from 'react'
import createStore from '@chegg-wt/core/state'
import { getBackendOptsWithOptimizelySafe } from '@chegg-wt/core/utils/backendOpts'
import MigrationNotificationBanner from '@chegg-wt/core/components/MigrationNotificationBanner'
import { Provider } from 'react-redux'
import { getCurrentProjectPreloadedState } from '@chegg-wt/core/utils/currentProject'
import { isServerSideContext } from '@chegg-wt/core/utils/isServerSideContext'
import { getActualStyleId } from '@chegg-wt/core/utils/styles'
import { getUserPreloadedState } from '@chegg-wt/core/utils/user'
import App from '@chegg-wt/core/components/App'
import CanonicalLink from '@chegg-wt/core/components/Head/CanonicalLink'
import { addGDPRCookieLinks } from '@chegg-wt/core/utils/cookies'
import DeIndex from '@chegg-wt/core/components/Head/DeIndex'
import siteEnv from '../state/env'
import { navItemsSelector } from '../config/nav'
import { routes } from '../config/routes'
import { exportTabIds } from '../config/exportTab'
import { head } from '../config/head'
import { CTFMTheme } from '../theme'
import { footerLinkColumns } from '../copy/footerLinkColumns'
import { legalContents } from '../copy/legalContents'
import {
  getSourceFromQuery,
  isSourceExact,
  getCanonicalUrl,
  isNonCanonicalRoute,
} from '../utils/routes'

const makeStore = (preloadedState = {}) => {
  return createStore(routes, siteEnv, preloadedState)
}

class CitationsApp extends NextApp {
  // Note: this will disable automatic static optimization
  // see here: https://nextjs.org/docs#automatic-static-optimization
  // we want `getInitialProps` here to set query object when loaded on server

  static async getInitialProps({ Component, ctx }) {
    let country
    let countryRegion

    const pageProps = Component.getInitialProps
      ? await Component.getInitialProps(ctx)
      : {}
    const featureFlags = isServerSideContext(ctx)
      ? await getBackendOptsWithOptimizelySafe(ctx)
      : {}
    const { tpudsa, userRole } = cookies(ctx)

    const preloadedState = {
      featureFlags,
      user: getUserPreloadedState(userRole),
    }

    if (ctx?.req) {
      country = ctx.req.headers['cloudfront-viewer-country']
      countryRegion = ctx.req.headers['cloudfront-viewer-country-region']
    }

    return {
      pageProps,
      preloadedState,
      tpudsa,
      country,
      countryRegion,
    }
  }

  render() {
    const {
      Component,
      pageProps,
      preloadedState,
      router,
      tpudsa,
      country,
      countryRegion,
    } = this.props
    const currentProject = getCurrentProjectPreloadedState({ router, getActualStyleId })
    const store = makeStore({ ...preloadedState, currentProject })
    const updatedFooterLinkColumns = addGDPRCookieLinks(
      footerLinkColumns,
      country,
      countryRegion
    )

    const config = {
      head,
      navItemsSelector,
      routes,
      siteEnv,
      exportTabIds,
      theme: CTFMTheme,
      notificationBanner: MigrationNotificationBanner,
      footerContents: {
        footerLinkColumns: updatedFooterLinkColumns,
        legalContents,
      },
      getSourceFromQuery,
      isSourceExact,
      getActualStyleId,
      useAdframeLayout: !store.getState().featureFlags.lvp,
      enableSvp: !store.getState().featureFlags.lvp,
    }

    const { route } = this.props.router

    const getSeoComponent = (router) => {
      const { route, query } = router
      const canonicalURL = getCanonicalUrl(route, query)
      const isNonCanonical = isNonCanonicalRoute(route)
      if (isNonCanonical) return <DeIndex />
      if (canonicalURL) return <CanonicalLink canonicalUrl={canonicalURL} />
    }

    // Only use getPageLayout when lvp is enabled
    const getPageLayout =
      (store.getState().featureFlags.lvp && Component.getPageLayout) || ((page) => page)
    const isHomePage = route === '/'

    return (
      <Provider store={store}>
        {getSeoComponent(this.props.router)}
        <App {...config} isHomePage={isHomePage}>
          {getPageLayout(<Component {...pageProps} tpudsa={tpudsa} />, pageProps)}
        </App>
      </Provider>
    )
  }
}

export default withRouter(CitationsApp)
